/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Style | `Header.Container`
 *
 * Created: Jan 28, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

#Header {
    backdrop-filter: blur(33px);
    /* mx-5 */
    padding: 0 3rem;
}