/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Styles| `Footer`
 *
 * Created: Jan 14, 2023
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

#Footer {
    /* py-5 */
    padding: 1rem 0;
    pointer-events: all;
    width: 100vw;
}