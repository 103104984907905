/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Style | `Page`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

#Background {
    height: 100vh;
    top: 0;
    position: fixed;
    width: 100vw;
}

#Foreground {
    /* mx-5 */
    margin: 0 3rem 0;
    position: relative;
    z-index: 1;
}