/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Style | `HomeModel`
 *
 * Created: Nov 5, 2022
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

#Title {
    background: linear-gradient(30deg, #19547b, #ffd89b);
    font-size: 15vw;
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#Subtitle {
    color: rgba(162, 160, 144, 100);
    font-weight: lighter;
}

#Slogan {
    font-style: italic;
    font-weight: normal;
    text-decoration-line: underline;
    text-decoration-style: dotted;
}

#Stack {
    position: absolute;
    pointer-events: none;
    top: 20vh;
}